<template>
<v-card flat title="WINNER">
    <SearchComponent @update:search="update_search" :filterSlotNames="filterSlotNames" :value="search" v-if="tab === 'one'">
        <template v-slot:actions>
            <v-select :items="actionsItems" hide-details v-model="actionValue" variant="solo-filled" label="Actions" @update:model-value="handle_action_change"></v-select>
        </template>
    </SearchComponent>
    <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" @click="toggle_tab_title()">View</v-tab>
        <v-tab value="two" @click="clear_edited_data()" v-if="tabTitle === 'Edit'">{{ tabTitle }}</v-tab>
    </v-tabs>
    <v-card-text>
        <v-tab-items v-model=" tab">
            <v-tab-item value="one" v-if="tab === 'one'">
                <div v-if="loader" style="text-align: center;">Loading...</div>

                <tableLayout :headers="headers" :items="InstantWinData" :slotNames="slotNames" @updatedItems="updated_items" :showSelect="true" :search="search" v-else>
                    <template v-slot:Actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="blue">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)" color="red darken-1">
                            mdi-delete
                        </v-icon>
                    </template>
                </tableLayout>
            </v-tab-item>
            <v-tab-item value="two" v-else>
                <addDrawInstantWin :instantWinnerDetails="instantWinnerDetails" :saveBtn="saveEdit" @switchInstantTab="switch_tab"></addDrawInstantWin>
            </v-tab-item>
        </v-tab-items>
    </v-card-text>
    <v-dialog v-model="dialogForBatch" max-width="630px" persistent>
        <v-card>
            <v-card-title class="text-size text-center">{{ dialogMessage }}</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close_selected_delete">Cancel</v-btn>
                <v-btn color="blue darken-1" text v-if="!dialogSelectBtn" @click="dialogFunction ? featured_batch_item() : delete_batch_item()">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
<successMessageVue :message="successMessage" :showSnackbar="showSnackbar" />
<logoutDia :showLogoutDialog="showLogoutDialog" />
</template>

<script>
import apiUrl from '@/api/allApis';
import addDrawInstantWin from "../drawInstantWinnerPages/addDrawInstantWin.vue"
import tableLayout from '../ReusableComponents/tableLayout.vue';
import SearchComponent from '../ReusableComponents/searchComponent.vue';
import successMessageVue from '../ReusableComponents/successMessage.vue';
import jwtMixin from '@/mixins/jwt.js'
import logoutDia from "../ReusableComponents/tokenExpireDia.vue"


import {
    markRaw
} from 'vue';
export default markRaw({
     mixins: [jwtMixin],
    components: {
        addDrawInstantWin,
        tableLayout,
        SearchComponent,
        successMessageVue,
        logoutDia
    },
    data() {
        return {
            showLogoutDialog: false,
            successMessage: "",
            showSnackbar: false,
            instantWinnerDetails: {
            },
            tab: null,
            tabTitle: "Add",
            saveEdit: false,
            loader: false,
            slotNames: ['Actions'],
            headers: [{
                    key: 'user_email',
                    title: 'User Email',
                },
                {
                    key: 'raffle_name',
                    title: 'Raffle Name'
                },
                {
                    key: 'position',
                    title: 'Position'
                },
                {
                    key: 'Actions',
                    title: 'Actions'
                },
            ],
            InstantWinData: [],
            actionsItems: ['Delete Selected Items'],
            filterSlotNames: ['actions'],
            actionValue: 'Select Action',
            dialogForBatch: false,
            dialogSelectBtn: false,
            search: '',
            selected: [],
            dialogMessage: '',
            singleDelete: false,
            singleItem: [],
            setFeatured: false
        }
    },
    created() {
        this.fetch_instant_winner()
    },
    methods: {
        show_snackbar(message) {
            this.showSnackbar = true;
            this.successMessage = message;
            setTimeout(() => {
                this.showSnackbar = false;
            }, 1000);
        },
        async featured_batch_item() {
            try {
                let batchIds = {
                    ids: this.selected,
                    featured: this.setFeatured,
                }
                await fetch(apiUrl.updateDrawWinnerFeature, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                        this.dialogForBatch = false;
                        this.actionValue = 'Select Action';
                        this.selected = [];
                        this.fetch_instant_winner();
                        }
                        else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        close_selected_delete() {
            this.dialogForBatch = false;
            this.actionValue = 'Select Action';
        },
        updated_items(newSelected) {
            this.selected = [...newSelected]
        },
        handle_action_change(selectedItem) {
            this.singleDelete = true;
            if (this.selected.length) {
                if (selectedItem === 'Delete Selected Items') {
                    this.dialogMessage = this.selected.length === 1 ? 'Are you sure you want to delete selected item?' : 'Are you sure you want to delete selected items?'
                    this.dialogSelectBtn = false;
                    this.dialogFunction = false;
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Set Featured') {
                    this.dialogMessage = 'Are you sure you want to Set Featured selected items?'
                    this.dialogSelectBtn = false;
                    this.setFeatured = true;
                    this.dialogFunction = true;
                    this.dialogForBatch = true;
                } else if (selectedItem === 'Unset Featured') {
                    this.dialogMessage = 'Are you sure you want to Unset Featured selected items?'
                    this.dialogSelectBtn = false;
                    this.setFeatured = false;
                    this.dialogFunction = true;
                    this.dialogForBatch = true;
                } else {
                    this.dialogSelectBtn = false;
                    this.dialogForBatch = false;
                }
            } else {
                this.dialogMessage = 'Please select atleast one item'
                this.dialogForBatch = true;
                this.dialogSelectBtn = true;
            }
        },
        async delete_batch_item() {
            try {
                let batchIds = {
                    ids: this.singleDelete ? this.selected.map((i) => {
                        return {
                            sort_id: i.sort_id
                        }
                    }) : this.singleItem
                }
                await fetch(apiUrl.deleteInstantWinnerContent, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                        body: JSON.stringify(batchIds)
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                        this.dialogForBatch = false;
                        this.actionValue = 'Select Action';
                        this.successMessage = "Successfully deleted...";
                        this.show_snackbar(this.successMessage);
                        this.selected = [];
                        this.singleItem = [];
                        this.fetch_instant_winner();
                        }  else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.dialogForBatch = false;
                this.actionValue = 'Select Action';
                throw error
            }
        },
        update_search(newSearch) {
            this.search = newSearch;
        },
        editItem(items) {
            this.instantWinnerDetails = items
            this.tab = "two"
            this.tabTitle = "Edit"
            this.saveEdit = true
        },
        clear_edited_data() {
            this.instantWinnerDetails = {
            }
            this.saveEdit = false
        },
        toggle_tab_title() {
            this.tabTitle = "Add"
            this.fetch_instant_winner()
        },
        deleteItem(item) {
            this.deletedInstantWin = item
            this.singleDelete = false;
            this.dialogFunction = false;
            this.singleItem = [{
                sort_id: this.deletedInstantWin.sort_id
            }];
            this.dialogMessage = 'Are you sure you want to delete this item?'
            this.dialogSelectBtn = false;
            this.dialogForBatch = true;
        },
        async fetch_instant_winner() {
            this.search = '';
            try {
                this.loader = true;
                await fetch(apiUrl.fetchInstantWinnerContent, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization':JSON.parse(this.jwt_token())
                        },
                    }).then((response) => response.json())
                    .then((data) => {
                        if (!data.status) {
                        this.loader = false
                        this.InstantWinData = data.SortedWinner
                        } else {
                            this.error.api = data.message ? data.message : data;
                            if (data.message === "Token Expired" || data.message === "Invalid Token") {
                                this.showLogoutDialog = true;
                            }
                        }
                    })
            } catch (error) {
                this.loader = false
                throw error
            }finally{
                this.selected = [];
            }
        },
        switch_tab(message) {
            this.show_snackbar(message)
            this.tab = "one"
            this.tabTitle = "Add"
            this.fetch_instant_winner()
        }
    }
})
</script>
